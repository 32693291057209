import Q from "q";


export default function asyncLoadJs(url: string, className: string) {
    return Q.Promise((resolve: (res: boolean) => void, reject: () => void) => {
        const srcArr = document.getElementsByTagName("script");
        let hasLoaded = false;
        for (let i = 0; i < srcArr.length; i++) {//判断当前js是否加载上
            hasLoaded = (srcArr[i].src == url) ? true : false;
        }
        if (hasLoaded) {
            resolve(hasLoaded);
            return;
        }else{
            const script = document.createElement('script')
            script.type = 'text/javascript';
            script.src = url;
            script.className = className;
            // document.body.appendChild(script);
            script.onload = () => {
                resolve(hasLoaded);
            }
            script.onerror = () => {
                reject();
            }
        }
        
        
    })
}


export function removeJs(filename: string){
    const allsuspects = document.getElementsByTagName("script")
    return Q.Promise((resolve) => {
        for (let i = 0; i < allsuspects.length + 1; i++){
            if(allsuspects[i].getAttribute("src")?.indexOf(filename) != -1){
                (allsuspects[i].parentNode as HTMLScriptElement).removeChild(allsuspects[i])
            }
        }
        resolve()
    })
}